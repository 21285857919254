import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../../Components/Input/Input';
import Button from '../../../../../../Components/Button/Button';
import { useEffect, useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { ModalUrls } from '../Style';
import { HeaderWrapper } from '../../../../../ConfigurationsRag/Style';
import { Website } from '../../../../../../Contexts/RagConfigContext';

interface ModalWebsitesProps {
  visible: boolean;
  onHide: () => void;
  handleAddWebsite: (data) => void;
  handleUpdateWebsite: (data) => void;
  currentWebSite: any;
}

const ModalWebsites = ({
  visible,
  onHide,
  handleAddWebsite,
  handleUpdateWebsite,
  currentWebSite,
}: ModalWebsitesProps) => {
  const { t } = useTranslation();
  const initialFormState = {
    name: '',
    urls: [],
  };
  const [formData, setFormData] = useState<Website>(initialFormState);
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const initValue = formData.urls.length > 0 ? formData.urls : [];

  const data = {
    id: currentWebSite?.id || '',
    ...formData,
  };

  useEffect(() => {
    if (currentWebSite) {
      setFormData({
        name: currentWebSite.name,
        urls: currentWebSite.urls,
      });
    } else {
      setFormData(initialFormState);
    }
  }, [currentWebSite]);

  const handleAddUrl = () => {
    setFormData({ ...formData, urls: [...formData.urls, currentUrl] });
    setCurrentUrl('');
  };

  const isUpdate = !!currentWebSite;

  const renderHeaderModal = (
    <HeaderWrapper>
      <h1 className='p-panel-title'>{t('config_rag.websites.title')}</h1>
    </HeaderWrapper>
  );

  return (
    <Dialog visible={visible} onHide={onHide} dismissableMask={true} header={renderHeaderModal}>
      <ModalUrls>
        <div className='form-addurls'>
          <Input
            label={t('general.name')}
            className={'input-name'}
            placeholder={t('general.name')}
            value={formData.name}
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value });
            }}
          />
          <Input
            label={t('general.url')}
            placeholder={t('general.url')}
            className={'input-url'}
            value={currentUrl}
            onChange={(e) => {
              setCurrentUrl(e.target.value);
            }}
          />
          <Button label={t('config_rag.websites.addWebSite')} onClick={handleAddUrl} />
        </div>
        <div>{t('config_rag.websites.urlList')}</div>
        <MultiSelect
          value={initValue}
          options={formData.urls}
          onChange={(e) => setFormData({ ...formData, urls: [...e.value] })}
          placeholder={t('general.url')}
          checkboxIcon='pi pi-times'
          emptyMessage={t('config_rag.websites.emptyMultiSelect')}
          display='chip'
          maxSelectedLabels={3}
          pt={{
            checkboxIcon: {
              style: { color: 'red', height: '16px', background: '#edf1f5' },
            },
            checkbox: {
              box: {
                style: {
                  border: 'none',
                  backgroundColor: '#edf1f5',
                  cursor: 'pointer',
                  pointerEvents: 'initial',
                },
              },
            },
            item: { style: { cursor: 'default', pointerEvents: 'none' } },
          }}
        />
        <Button
          label={t(isUpdate ? 'general.update' : 'general.create')}
          className={'button-add-website'}
          onClick={() => (isUpdate ? handleUpdateWebsite(data) : handleAddWebsite(data))}
        />
      </ModalUrls>
    </Dialog>
  );
};

export default ModalWebsites;
