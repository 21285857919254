import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { useRef, useState } from 'react';
import { RagConfigProps, useRagConfig } from '../../../../../../Contexts/RagConfigContext';
import { addRagConfigurationSourceFile } from '../../../../../../Tools/Api';
import { toastError, toastSuccess } from '../../../../../../Tools/Toast';
import { ProgressBar } from 'primereact/progressbar';
import { useTranslation } from 'react-i18next';
import { ModalFilesUploadWrapper } from '../Style';
import { useUserContext } from '../../../../../../Contexts/UserContext';
import { HeaderWrapper } from '../../../../../ConfigurationsRag/Style';

interface ModalFilesUploadProps {
  visible: boolean;
  onHide: () => void;
  setVisible: (visible: boolean) => void;
}

const ModalFilesUpload = ({ visible, onHide, setVisible }: ModalFilesUploadProps) => {
  const { selectedRagConfiguration, setSelectedRagConfiguration } = useRagConfig();
  const [uploadProgress, setUploadProgress] = useState(0);
  const { t } = useTranslation();
  const fileUploadRef = useRef<FileUpload>(null);
  const { casClient } = useUserContext();
  const token = casClient.getToken();

  const renderProgressBar = () => {
    return <ProgressBar value={uploadProgress} />;
  };

  const uploadEachFile = async (e: any) => {
    let updatedConfiguration: RagConfigProps = {
      ...selectedRagConfiguration,
      id: selectedRagConfiguration?.id || '',
      name: selectedRagConfiguration?.name || null,
      botUUID: selectedRagConfiguration?.botUUID || null,
      content: {
        ...selectedRagConfiguration?.content,
        sources: {
          ...selectedRagConfiguration?.content?.sources,
          files: [...(selectedRagConfiguration?.content?.sources?.files || [])],
        },
      },
    };
    for (let i = 0; i < e.files.length; i++) {
      selectedRagConfiguration &&
        (await addRagConfigurationSourceFile(selectedRagConfiguration?.id, e.files[i], token, (progress) => {
          setUploadProgress(progress / e.files.length);
          console.log(progress);
        })
          .then((res) => {
            updatedConfiguration = {
              ...updatedConfiguration,
              content: {
                ...updatedConfiguration.content,
                sources: {
                  ...updatedConfiguration.content?.sources,
                  files: [...(updatedConfiguration.content?.sources?.files || []), res],
                },
              },
            };
          })
          .catch(() => {
            if (fileUploadRef.current) fileUploadRef.current.clear();
            toastError(t('config_rag.uploadFile.uploadError'));
          }));
    }
    setSelectedRagConfiguration && setSelectedRagConfiguration(updatedConfiguration);
    if (fileUploadRef.current) fileUploadRef.current.clear();
    toastSuccess(t('config_rag.uploadFile.uploadSuccess'));
    setVisible(false);
  };

  const renderHeaderModal = (
    <HeaderWrapper>
      <h1 className='p-panel-title'>{t('config_rag.uploadFile.title')}</h1>
    </HeaderWrapper>
  );

  const labelForUploadContent = () => (
    <ModalFilesUploadWrapper>
      <div className={'content-label'}>{t('config_rag.uploadFile.contentLabel')}</div>
    </ModalFilesUploadWrapper>
  );

  return (
    <>
      <Dialog visible={visible} onHide={onHide} dismissableMask={true} header={renderHeaderModal}>
        <ModalFilesUploadWrapper>
          <h2 className='p-panel-subtitle'>{t('config_rag.uploadFile.subtitle')}</h2>
          <FileUpload
            ref={fileUploadRef}
            progressBarTemplate={renderProgressBar()}
            name={'file'}
            maxFileSize={10485760}
            uploadHandler={uploadEachFile}
            customUpload={true}
            accept='*/*'
            multiple={true}
            emptyTemplate={labelForUploadContent()}
            contentStyle={{ height: '200px', overflow: 'auto' }}
            cancelOptions={{ style: { display: 'none' } }}
            uploadLabel={t('config_rag.uploadFile.upload')}
            chooseLabel={t('config_rag.uploadFile.choose')}
          />
        </ModalFilesUploadWrapper>
      </Dialog>
    </>
  );
};

export default ModalFilesUpload;
