import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentBot } from '../Redux/Bot/selectors';
import { getRagConfigurationsForBotUuid } from '../Tools/Api';
import { useUserContext } from './UserContext';

interface RagConfigProviderProps {
  ragConfigs: RagConfigProps[];
  setRagConfigs?: (ragConfig: RagConfigProps[]) => void;
  selectedRagConfiguration: RagConfigProps | null;
  setSelectedRagConfiguration: (ragConfig: RagConfigProps | null) => void | null;
  setLoading: (loading: boolean) => void;
  loading: boolean;
}

interface File {
  name: string;
  id: string;
  addedBy: string;
  addedAt: number;
  storageUUID: string;
}

export interface Sharepoint {
  id?: string;
  name: string;
  addedBy?: string;
  addedAt?: string;
  clientId: string;
  clientSecret: string;
  tenantId: string;
  siteIds: string[];
}

interface Content {
  sources: Sources;
}

interface Sources {
  files?: File[];
  sharepoints?: Sharepoint[];
  websites?: Website[];
}

export interface Website {
  name: string;
  id?: string;
  addedBy?: string;
  addedAt?: number;
  updatedBy?: string;
  updatedAt?: number;
  urls: string[];
  domainToCrawl?: string;
  sitemapUrl?: string;
}

export interface RagConfigProps {
  id: string;
  name: string | null;
  serverCode?: string;
  botUUID: string | null;
  createdAt?: string;
  updatedAt?: string;
  author?: string;
  modifiedBy?: string;
  content?: Content;
}

export const useRagConfig = () => useContext<RagConfigProviderProps>(RagConfigContext);
export const RagConfigContext = createContext({} as RagConfigProviderProps);

const RagConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [ragConfigs, setRagConfigs] = useState<RagConfigProps[]>([]);
  const [selectedRagConfiguration, setSelectedRagConfiguration] = useState<RagConfigProps | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const botUUID = useSelector(getCurrentBot)?.botUUID;
  const { casClient } = useUserContext();
  const token = casClient.getToken();

  useEffect(() => {
    if (botUUID) {
      getRagConfigurationsForBotUuid(botUUID, token)
        .then((res: any) => {
          setRagConfigs(res);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [botUUID]);

  useEffect(() => {
    if (selectedRagConfiguration) {
      setRagConfigs(
        ragConfigs?.map((config) => (config.id === selectedRagConfiguration.id ? selectedRagConfiguration : config))
      );
    }
  }, [selectedRagConfiguration]);

  const props = {
    ragConfigs,
    setRagConfigs,
    selectedRagConfiguration,
    setSelectedRagConfiguration,
    setLoading,
    loading,
  };

  return <RagConfigContext.Provider children={children} value={props} />;
};

export default RagConfigProvider;
