import React, { useState } from 'react';
import Input from '../../../../../../Components/Input/Input';
import Button from '../../../../../../Components/Button/Button';
import { useTranslation } from 'react-i18next';
import { SiteIds } from '../Style';
import { MultiSelect } from 'primereact/multiselect';

interface SiteIdsInputProps {
  siteIds: string[];
  setSiteIds: (siteIds: string[]) => void;
}

const SiteIdsInput = ({ siteIds, setSiteIds }: SiteIdsInputProps) => {
  const { t } = useTranslation();
  const [newSiteId, setNewSiteId] = useState('');

  const handleSiteIdChange = (value) => {
    setNewSiteId(value);
  };

  const handleAddSiteId = () => {
    setSiteIds([...siteIds, newSiteId]);
    setNewSiteId('');
  };

  const initValue = siteIds.length > 0 ? siteIds : [];

  const isValid = newSiteId !== '';

  return (
    <>
      <SiteIds>
        <Input
          value={newSiteId}
          label={t('config_rag.sharepoint.siteID')}
          onChange={(e) => handleSiteIdChange(e.target.value)}
          placeholder={t('site ID')}
          invalid={!isValid}
          className={'input-siteid'}
        />
        <Button
          label={t('general.add')}
          className={'button-add-siteid'}
          icon='icon-add'
          onClick={handleAddSiteId}
          disabled={!isValid}
        />
      </SiteIds>
      <MultiSelect
        value={initValue}
        options={siteIds}
        onChange={(e) => setSiteIds([...e.value])}
        style={{ minWidth: '100%' }}
        checkboxIcon='pi pi-times'
        placeholder={t('site ID')}
        pt={{
          checkboxIcon: {
            style: { color: 'red', height: '16px', background: '#edf1f5' },
          },
          checkbox: {
            box: {
              style: {
                border: 'none',
                backgroundColor: '#edf1f5',
                cursor: 'pointer',
                pointerEvents: 'initial',
              },
            },
          },
          item: { style: { cursor: 'default', pointerEvents: 'none' } },
        }}
      />
    </>
  );
};

export default SiteIdsInput;
