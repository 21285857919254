/* ----------------------------------------------------------
  ENV PATTERN CONSTANTS
----------------------------------------------------------- */

export const STAGING_PATTERN = '';
export const PRODUCTION_PATTERN = '';

/* ----------------------------------------------------------
  APP CONSTANTS
----------------------------------------------------------- */

export const CHATBOX_VERSION = 'chatbox_edge_2024-09-17';

export const APP_STATUS = {
  REQUEST: 'init:request',
  SUCCESS: 'init:success',
  FAILURE: 'init:failure',
};

export const NODE_ENV = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
};

export const APP_ENV = {
  PREPROD: 'PREPROD',
  PROD: 'PROD',
};

export const APP_PATH = {
  BLANK: '/',
  CALLBACK: '/callback',
  AUTH: '/auth',
  LOGOUT: '/logout',
  CONFIGURATIONS: '/configurations',
  EDIT: '/configurations/:type/:id',
  NEW_CONFIG: '/new-configuration',
  NO_BOT: '/no-bot-found',
  CONFIG_RAG: '/configurations-rag',
};

export const APP_URL = {
  fr: {
    doc: 'https://docs.dydu.ai',
    connectorGuide: 'https://docs.dydu.ai/integrations/canaux/connecteurs/teams',
    metaGuide: 'https://docs.dydu.ai/integrations/canaux/connecteurs/meta',
  },
  en: {
    doc: 'https://docs-en.dydu.ai',
    connectorGuide: 'https://docs-en.dydu.ai/integration/channels/connector/teams',
    metaGuide: 'https://docs-en.dydu.ai/integration/channels/connector/meta',
  },
};

/* ----------------------------------------------------------
    METHOD CONSTANTS
----------------------------------------------------------- */

export const METHOD = {
  GET: 'get',
  PUT: 'put',
  POST: 'post',
  DELETE: 'delete',
  PATCH: 'patch',
};

/* ----------------------------------------------------------
  ACTION VERBS
----------------------------------------------------------- */

export const PERSIST_REHYDRATE = 'persist/REHYDRATE';

export const ARCHIVE = 'ARCHIVE';
export const CANCEL = 'CANCEL';
export const CLOSE = 'CLOSE';
export const CONFIGURATIONS = 'CONFIGURATIONS';
export const CONSULTATION_SPACES = 'CONSULTATION_SPACES';
export const CREATE = 'CREATE';
export const CREATE_ONE = 'CREATE_ONE';
export const DELETE = 'DELETE';
export const DELETE_ONE = 'DELETE_ONE';
export const DEPLOY = 'DELETE_ONE';
export const DOWNLOAD = 'DOWNLOAD';
export const FAIL = 'FAIL';
export const FAILURE = 'FAILURE';
export const GET = 'GET';
export const GET_ALL = 'GET_ALL';
export const GET_ONE = 'GET_ONE';
export const IMPORT_ONE = 'IMPORT_ONE';
export const INIT = 'INIT';
export const RELEASES = 'RELEASES';
export const CHECK = 'CHECK';
export const LOADING = 'LOADING';
export const LANGUAGES = 'LANGUAGES';
export const POST = 'POST';
export const META = 'META';
export const WEBHOOK = 'WEBHOOK';
export const CREDENTIALS = 'CREDENTIALS';
export const LINKED_OBJECT = 'LINKED_OBJECT';
export const PUBLISH = 'PUBLISH';
export const PUBLISH_ONE = 'PUBLISH_ONE';
export const RESTORE_ONE = 'RESTORE_ONE';
export const PUT = 'PUT';
export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const SAVE_AND_PUBLISH = 'SAVE_AND_PUBLISH';
export const SYNC = 'SYNC';
export const UPDATE = 'UPDATE';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_SLUG = 'UPDATE_SLUG';
export const UPDATE_ONE = 'UPDATE_ONE';
export const UPDATE_IMAGES_DATA = 'UPDATE_IMAGES_DATA';
export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export const UPLOAD_EXCEL = 'UPLOAD_EXCEL';
export const UPLOAD_JSON = 'UPLOAD_JSON';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const UPLOAD_CONNECTOR = 'UPLOAD_CONNECTOR';
export const UPLOAD_WEBSITE = 'UPLOAD_WEBSITE';
export const ZIP = 'ZIP';

/* ----------------------------------------------------------
  IFRAME LOCALSTORAGE CONSTANTS
----------------------------------------------------------- */

export const DyduStorage = {
  WIZARD: 'dydu.wizard.data',
  MAIN: 'dydu.main',
  CSS: 'dydu.css',
  IMAGES: 'dydu.images',
  BOT_ID: 'dydu.botId',
  SERVERS: 'dydu.servers',
  LOCALE: 'dydu.locale',
  CURRENT_BOT: 'dydu.current.bot',
  WORDING: 'dydu.wording',
  SPACE: 'dydu.space',
  BANNER: 'dydu.banner',
  ONBOARDING: 'dydu.onboarding',
  HOST: 'dydu-host',
  PROXY: 'dydu-proxy',
  TOKEN: 'dydu-token',
  LOGIN: 'dydu-login', // shared with back-office (onPrem context)
  IS_CHANNELS: 'dydu.isChannels',
  NEXT: 'dydu-next',
};

export const ATRIA_BOTUUID = 'channels.atria_botuuid';
export const AUTH_PATH = '/#/auth';

/* ----------------------------------------------------------
  CONFIG TYPES
----------------------------------------------------------- */

export const CONFIG_TYPES = {
  WEBSITE: 'website',
  CUSTOMER: 'customer',
  TEAMS: 'teams',
  HANGOUT: 'hangout',
  META: 'meta',
  SLACK: 'slack',
  CALLBOT: 'callbot',
  RAG: 'rag',
};

/* ----------------------------------------------------------
  FORMDATA TYPES
----------------------------------------------------------- */

export const FORMDATA_TYPES = {
  AVATAR: 'avatar',
  FILE: 'file',
  IMAGE: 'image',
  ZIPFILE: 'zipFile',
  DOWNLOAD: 'download',
};

export const AVATAR_TYPE = {
  LOGO: 'LOGO',
  TEASER: 'TEASER',
  COLOR: 'COLOR',
  OUTLINE: 'OUTLINE',
  UNDERSTOOD: 'UNDERSTOOD',
  MISUNDERSTOOD: 'MISUNDERSTOOD',
  LIVECHAT: 'LIVECHAT',
  REWORD: 'REWORD',
  ONBOARDING1: 'ONBOARDING1',
  ONBOARDING2: 'ONBOARDING2',
  ONBOARDING3: 'ONBOARDING3',
};

export const UPLOAD_TYPE = {
  XLSX: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  ZIP: 'zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed',
  IMAGE: 'image/*',
};

/* ----------------------------------------------------------
  THEME
----------------------------------------------------------- */

export const THEME_COLORS = [
  '#0076BA',
  '#56C1FF',
  '#00A1FF',
  '#3636B9',
  '#004D80',
  '#73FDEA',
  '#16E7CF',
  '#00AB8E',
  '#006C65',
  '#88FA4E',
  '#61D836',
  '#1DB100',
  '#017100',
  '#000000',
  '#FFF056',
  '#FFD932',
  '#FEAE00',
  '#F27200',
  '#FF968D',
  '#FF644E',
  '#EE220C',
  '#B51700',
  '#FF95CA',
  '#FF42A1',
  '#D41876',
  '#970E53',
  '#5E5E5E',
  '#95A3B3',
];
