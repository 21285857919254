import FilesList from './Components/FilesList';
import SharePointList from './Components/SharePointList';
import { DisplayContainer, StepperRagWrapper } from './Style';
import { useNavigate, useParams } from 'react-router-dom';
import { useRagConfig } from '../../../../../Contexts/RagConfigContext';
import { generateExcel, getRagIndexationStatuts, indexRagConfiguration } from '../../../../../Tools/Api';
import Button from '../../../../../Components/Button/Button';
import { useEffect, useState } from 'react';
import { Tag } from 'primereact/tag';
import { useUserContext } from '../../../../../Contexts/UserContext';
import { useTranslation } from 'react-i18next';
import WebSitesList from './Components/WebSitesList';
import { toastError, toastSuccess } from '../../../../../Tools/Toast';

const Display = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [indexationStatus, setIndexationStatus] = useState('');
  const { setSelectedRagConfiguration, selectedRagConfiguration } = useRagConfig();
  const { casClient } = useUserContext();
  const { t } = useTranslation();
  const token = casClient.getToken();

  const handlePrevious = () => {
    navigate(-1);
    setSelectedRagConfiguration && setSelectedRagConfiguration(null);
  };

  const isSourcesEmpty =
    selectedRagConfiguration?.content?.sources?.files?.length === 0 &&
    selectedRagConfiguration?.content?.sources?.sharepoints?.length === 0;

  const isIndexationInProgress = indexationStatus === t('config_rag.general.inProgress');

  const shouldDisableActions = isSourcesEmpty || isIndexationInProgress;

  const checkIndexationStatus = () => {
    if (id) {
      getRagIndexationStatuts(id, token).then((response) => {
        const statusMap = {
          '': t('config_rag.general.noIndexation'),
          IN_PROGRESS: t('config_rag.general.inProgress'),
          COMPLETED: t('config_rag.general.completed'),
          COMPLETED_WITH_ERRORS: t('config_rag.general.completedWithErrors'),
          FAILED: t('config_rag.general.failed'),
        };
        setIndexationStatus(statusMap[response]);
      });
    }
  };

  useEffect(() => {
    if (indexationStatus === '') {
      checkIndexationStatus();
    }
    if (isIndexationInProgress) {
      const intervalId = setInterval(() => {
        if (id) {
          checkIndexationStatus();
        }
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [isIndexationInProgress, id]);

  const handleIndexConfiguration = (id: string) => {
    indexRagConfiguration(id, token)
      .then(() => {
        getRagIndexationStatuts(id, token).then(() => {
          setIndexationStatus(t('config_rag.general.inProgress'));
        });
      })
      .catch(() => {
        toastError("Erreur lors de l'indexation");
      });
  };

  const handleGenerateExcel = (id: string) => {
    generateExcel(id, token)
      .then(() => {
        toastSuccess(t('config_rag.general.generateExcelSuccess'));
      })
      .catch(() => {
        toastError(t('config_rag.general.generateExcelError'));
      });
  };

  const severity = (indexationStatus) => {
    switch (indexationStatus) {
      case t('config_rag.general.completed'):
        return 'success';
      case t('config_rag.general.completedWithErrors'):
        return 'danger';
      case t('config_rag.general.inProgress'):
        return 'info';
      case t('config_rag.general.failed'):
        return 'danger';
      case '':
        return 'warning';
    }
  };

  return (
    <>
      <DisplayContainer>
        <h1>{selectedRagConfiguration?.name}</h1>
        <div className='files-wrapper'>
          <span>{t('config_rag.general.fileListTitle')}</span>
          <FilesList />
        </div>
        <div className='sharepoint-wrapper'>
          <span>{t('config_rag.general.sharepointListTitle')}</span>
          <SharePointList />
        </div>
        <div className='website-wrapper'>
          <span>{t('config_rag.general.webSiteTitle')}</span>
          <WebSitesList />
        </div>
      </DisplayContainer>
      <StepperRagWrapper>
        <div className='inner-content'>
          <Button
            label={t('config_rag.general.backToCollections')}
            onClick={handlePrevious}
            className='p-button-text p-button-secondary'
            icon='icon-caret-left'
          />
          <div className='stepper-rag-actions'>
            <Button
              label={t('config_rag.general.knowledge')}
              onClick={() => id && handleGenerateExcel(id)}
              disabled={shouldDisableActions}
            />
            <Button
              label={t('config_rag.general.indexation')}
              onClick={() => id && handleIndexConfiguration(id)}
              disabled={shouldDisableActions}
            />
            <Tag value={indexationStatus} severity={severity(indexationStatus)} />
          </div>
        </div>
      </StepperRagWrapper>
    </>
  );
};

export default Display;
