import styled from 'styled-components';

export const IconTrigger = styled.div`
  position: relative;
  display: flex;
  border-radius: 36px;
  width: 36px;
  height: 36px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: rgba(112, 145, 216, 0.1);
  }
`;

export const CreateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  height: 400px;
  margin: 50px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.color_grey__300};
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 28px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.color_black};
  }

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.color_black};
  }

  h3 {
    font-size: 14px;
    font-style: italic;
    color: ${({ theme }) => theme.color_grey__500};
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .input-wrapper {
    gap: 10px;
    margin: 0;
  }

  .p-dialog-header-icon {
    display: contents;
  }

  .actions-modal {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }

  .delete-button {
    background-color: ${({ theme }) => theme.color_critical};
    border: none;
  }

  h1 {
    font-size: 28px;
    color: ${({ theme }) => theme.color_grey__600};
  }

  h2 {
    font-size: 16px;
    white-space: break-spaces;
    color: ${({ theme }) => theme.color_grey__500};
  }
`;

export const HeaderWrapper = styled.div`
  .p-dialog-header {
    margin-top: 20px;
  }

  h1 {
    font-size: 20px;
    color: ${({ theme }) => theme.color_black};
    display: contents;
  }
`;
