import { Dialog } from 'primereact/dialog';
import Input from '../../../../../../Components/Input/Input';
import { useEffect, useState } from 'react';
import { Sharepoint } from '../../../../../../Contexts/RagConfigContext';
import Button from '../../../../../../Components/Button/Button';
import { ModalHeaderSharePoint, ModalSharePoint } from '../Style';
import Icon from '../../../../../../Components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import SiteIdsInput from './SiteIdsInput';

interface ModalCreateSharepointProps {
  visible: boolean;
  onHide: () => void;
  handleAddSharepoint: (data) => void;
  handleUpdateSharepoint: (data) => void;
  currentSharepoint: Sharepoint | null;
}

const ModalSharepoint = ({
  visible,
  onHide,
  handleAddSharepoint,
  handleUpdateSharepoint,
  currentSharepoint,
}: ModalCreateSharepointProps) => {
  const initialFormState = {
    name: '',
    clientId: '',
    clientSecret: '',
    tenantId: '',
    siteIds: [],
  };
  const [formData, setFormData] = useState<Sharepoint>(initialFormState);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentSharepoint) {
      setFormData({
        name: currentSharepoint.name,
        clientId: currentSharepoint.clientId,
        clientSecret: currentSharepoint.clientSecret,
        tenantId: currentSharepoint.tenantId,
        siteIds: currentSharepoint?.siteIds,
      });
    } else {
      setFormData(initialFormState);
    }
  }, [currentSharepoint]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { siteIds, ...restFormData } = formData;
  const formDataWithoutSiteIds = restFormData;

  const labelNameForEachKey = {
    name: 'general.name',
    clientId: 'config_rag.sharepoint.clientID',
    clientSecret: 'config_rag.sharepoint.clientSecret',
    tenantId: 'config_rag.sharepoint.tenantID',
  };

  const data = {
    id: currentSharepoint?.id || '',
    ...formData,
  };

  const handleCancelChanges = () => {
    onHide();
    setFormData(initialFormState);
  };

  const handleSiteIdsChange = (newSiteIds) => {
    setFormData({ ...formData, siteIds: newSiteIds });
  };

  const titleAddOrUpdate = currentSharepoint
    ? 'config_rag.sharepoint.modalTitleEdit'
    : 'config_rag.sharepoint.modalTitleAdd';

  const renderHeaderModalSharePoint = () => {
    return (
      <>
        <h2>{t(titleAddOrUpdate)}</h2>
        <ModalHeaderSharePoint>
          <div className='title'>{t('config_rag.sharepoint.modalDocumentation')}</div>
          <div className='subtitle'>{t('config_rag.sharepoint.modalSubtitle')}</div>
          <div className='caret'>
            <Icon icon='caret-right' color='white' />
          </div>
        </ModalHeaderSharePoint>
      </>
    );
  };

  const isUpdate = !!currentSharepoint;
  const isFormValid = Object.values(formData).every((value) => value.length > 0);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  return (
    <Dialog visible={visible} onHide={onHide} dismissableMask={true} header={renderHeaderModalSharePoint}>
      <ModalSharePoint>
        {Object.keys(formDataWithoutSiteIds).map((key, index) => (
          <Input
            key={index}
            value={formDataWithoutSiteIds[key as keyof typeof formDataWithoutSiteIds]}
            onChange={(e) => handleChange(e, key)}
            placeholder={key}
            label={t(labelNameForEachKey[key as keyof typeof labelNameForEachKey])}
            maxLength={50}
            invalid={formData[key as keyof typeof formData]?.length === 0}
          />
        ))}
        <SiteIdsInput siteIds={formData.siteIds} setSiteIds={handleSiteIdsChange} />
        <div className='button-container'>
          <Button
            label={t(isUpdate ? 'general.update' : 'general.create')}
            onClick={() => (isUpdate ? handleUpdateSharepoint(data) : handleAddSharepoint(data))}
            icon='icon-check'
            disabled={!isFormValid}
          />
          <Button
            label={t('general.cancel')}
            onClick={handleCancelChanges}
            icon='icon-close'
            className='button-cancel'
          />
        </div>
      </ModalSharePoint>
    </Dialog>
  );
};
export default ModalSharepoint;
