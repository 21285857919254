import { Panel } from 'primereact/panel';
import { Sharepoint, useRagConfig } from '../../../../../../Contexts/RagConfigContext';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useUserContext } from '../../../../../../Contexts/UserContext';
import { toastError, toastSuccess } from '../../../../../../Tools/Toast';
import {
  addRagConfigurationSharePoint,
  deleteRagConfigurationSource,
  updateRagConfigurationSharepoint,
} from '../../../../../../Tools/Api';
import { IconTrigger } from '../../../../../ConfigurationsRag/Style';
import Icon from '../../../../../../Components/Icon/Icon';
import { PrimeIcons } from 'primereact/api';
import DataTable from '../../../../../../Components/DataTable/DataTable';
import Button from '../../../../../../Components/Button/Button';
import ModalSharepoint from './ModalSharepoint';
import sharePoint from '../../../../../../Assets/Images/sharepoint.png';
import { EmptyFilesWrapper } from '../Style';
import ModalDelete from '../../../../../ConfigurationsRag/Components/ModalDelete';

const SharePointList = () => {
  const { selectedRagConfiguration, setSelectedRagConfiguration } = useRagConfig();
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<string>('');
  const [currentSharepoint, setCurrentSharepoint] = useState<Sharepoint | null>(null);
  const { t } = useTranslation();
  const { casClient } = useUserContext();
  const token = casClient.getToken();
  const sharePointContent = selectedRagConfiguration?.content?.sources?.sharepoints?.map((sharepoint) => {
    return sharepoint;
  });
  const isSharepointInRagConfig =
    selectedRagConfiguration?.content?.sources?.sharepoints?.length &&
    selectedRagConfiguration?.content?.sources?.sharepoints?.length > 0;
  const sharePointColumns = [
    { id: 'name', title: t('general.name'), style: { width: '25%' } },
    {
      id: 'addedBy',
      title: t('general.addedBy'),
      style: { width: '25%' },
    },
    {
      id: 'addedAt',
      title: t('general.createdAt'),
      style: { width: '25%' },
      body: (rowData: any) => {
        const date = new Date(rowData.updatedAt);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${t('general.at')} ${hours}:${minutes}`;
      },
    },
    {
      id: 'actions',
      title: t('general.actions'),
      style: { width: '25%' },
      body: (rowData: any) => {
        return (
          <div className={'actions-list'}>
            <IconTrigger
              onClick={(event) => {
                event.stopPropagation();
                setIdToDelete(rowData.id);
                setVisibleDelete(true);
              }}
            >
              <Icon color='red' icon='icon-trash' size={55} />
            </IconTrigger>
            <IconTrigger
              onClick={(event) => {
                event.stopPropagation();
                setCurrentSharepoint(rowData);
                setVisible(true);
              }}
            >
              <Icon color='black' icon='icon-pencil' size={55} />
            </IconTrigger>
          </div>
        );
      },
    },
  ];

  const handleDeleteSharepoint = () => {
    selectedRagConfiguration &&
      deleteRagConfigurationSource(selectedRagConfiguration?.id, idToDelete || '', token)
        .then(() => {
          setSelectedRagConfiguration &&
            setSelectedRagConfiguration({
              ...selectedRagConfiguration,
              content: {
                ...selectedRagConfiguration.content,
                sources: {
                  ...selectedRagConfiguration.content?.sources,
                  sharepoints: selectedRagConfiguration.content?.sources?.sharepoints?.filter((file) => {
                    return file.id !== idToDelete;
                  }),
                },
              },
            });
          toastSuccess(t('config_rag.sharepoint.deleteSuccess'));
        })
        .catch(() => {
          toastError(t('config_rag.sharepoint.deleteError'));
        });
  };
  const handleAddSharepoint = (data) => {
    selectedRagConfiguration &&
      addRagConfigurationSharePoint(selectedRagConfiguration?.id, data, token)
        .then((res) => {
          setSelectedRagConfiguration &&
            setSelectedRagConfiguration({
              ...selectedRagConfiguration,
              content: {
                ...selectedRagConfiguration.content,
                sources: {
                  ...selectedRagConfiguration.content?.sources,
                  sharepoints: [...(selectedRagConfiguration.content?.sources?.sharepoints || []), res],
                },
              },
            });
          toastSuccess(t('config_rag.sharepoint.addSuccess'));
          setVisible(false);
          setCurrentSharepoint(null);
        })
        .catch(() => {
          toastError(t('config_rag.sharepoint.addError'));
        });
  };

  const handleUpdateSharepoint = (data) => {
    selectedRagConfiguration &&
      updateRagConfigurationSharepoint(selectedRagConfiguration?.id, data, token)
        .then((res) => {
          setSelectedRagConfiguration &&
            setSelectedRagConfiguration({
              ...selectedRagConfiguration,
              content: {
                ...selectedRagConfiguration.content,
                sources: {
                  ...selectedRagConfiguration.content?.sources,
                  sharepoints: selectedRagConfiguration.content?.sources?.sharepoints?.map((sp) =>
                    sp.id === data.id ? res : sp
                  ),
                },
              },
            });
          toastSuccess(t('config_rag.sharepoint.updateSuccess'));
          setVisible(false);
        })
        .catch(() => {
          toastError(t('config_rag.sharepoint.updateError'));
        });
  };

  const renderSharePointPanelHeader = () => {
    return (
      <div className={'sharepoint-header'}>
        <div>
          <h1 className='p-panel-title'>{t('config_rag.sharepoint.title')}</h1>
        </div>
        <Button
          label={t('config_rag.sharepoint.addSharepoint')}
          icon='pi pi-plus'
          onClick={() => {
            setCurrentSharepoint(null);
            setVisible(true);
          }}
        />
        <ModalSharepoint
          visible={visible}
          onHide={() => setVisible(false)}
          handleAddSharepoint={handleAddSharepoint}
          handleUpdateSharepoint={handleUpdateSharepoint}
          currentSharepoint={currentSharepoint}
        />
      </div>
    );
  };

  return isSharepointInRagConfig ? (
    <>
      <Panel
        header={renderSharePointPanelHeader()}
        toggleable
        collapsed={false}
        key={'filesRag'}
        expandIcon={PrimeIcons.CHEVRON_DOWN}
        collapseIcon={PrimeIcons.CHEVRON_UP}
        pt={{
          header: { style: { display: 'flex', flexDirection: 'row-reverse', gap: '20px' } },
        }}
      >
        <DataTable data={sharePointContent} columns={sharePointColumns} rowClassName={null} rows={5} />
        <ModalDelete
          visible={visibleDelete}
          setVisible={setVisibleDelete}
          onHide={() => setVisibleDelete(false)}
          handleDelete={handleDeleteSharepoint}
        />
      </Panel>
    </>
  ) : (
    <EmptyFilesWrapper>
      <div className='inner-content' onClick={() => setVisible(true)}>
        <img src={sharePoint} alt={'sharepoint-logo'} />
        <h1 className='title'>{t('config_rag.sharepoint.titleEmpty')}</h1>
        <h2 className='subtitle'>{t('config_rag.sharepoint.subtitleEmpty')}</h2>
      </div>
      <ModalSharepoint
        visible={visible}
        onHide={() => setVisible(false)}
        handleAddSharepoint={handleAddSharepoint}
        handleUpdateSharepoint={handleUpdateSharepoint}
        currentSharepoint={currentSharepoint}
      />
    </EmptyFilesWrapper>
  );
};

export default SharePointList;
